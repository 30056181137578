var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          ref: "filters",
          attrs: {
            title: _vm.$t("Filtros"),
            searching: _vm.fetching,
            "show-search-button": false,
          },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c("e-button", {
                    staticClass: "ml-1 btn-sm-block",
                    attrs: {
                      id: "download",
                      variant: "primary",
                      icon: "download",
                      busy: _vm.fetching,
                      text: _vm.$t("Baixar Relatório"),
                    },
                    on: { click: _vm.downloadReport },
                  }),
                  _c("a", {
                    ref: "downloadLink",
                    staticStyle: { display: "none" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "5" } },
                [
                  _c("e-store-combo", {
                    attrs: { id: "filter-store", required: false },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-search-sku", {
                    attrs: {
                      id: "filter-sku",
                      required: false,
                      label: _vm.$t("Produto"),
                    },
                    model: {
                      value: _vm.filters.skuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "skuId", $$v)
                      },
                      expression: "filters.skuId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "filter-brand",
                      type: "vue-select",
                      label: _vm.$t("Marca"),
                      placeholder: _vm.$t("Todas"),
                      options: _vm.getComboBrands,
                    },
                    model: {
                      value: _vm.filters.brandId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "brandId", $$v)
                      },
                      expression: "filters.brandId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "filter-group",
                      type: "vue-select",
                      label: _vm.$t("Grupo"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.getComboGroups,
                    },
                    model: {
                      value: _vm.filters.groupId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "groupId", $$v)
                      },
                      expression: "filters.groupId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "filter-period",
                      type: "date-range-picker",
                      label: _vm.$t("Período"),
                      "time-picker": false,
                      validation: "required",
                    },
                    model: {
                      value: _vm.filters.period,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "period", $$v)
                      },
                      expression: "filters.period",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }