<template>
  <div>
    <e-filters
      ref="filters"
      :title="$t('Filtros')"
      :searching="fetching"
      :show-search-button="false"
    >
      <template #after_buttons>
        <e-button
          id="download"
          class="ml-1 btn-sm-block"
          variant="primary"
          icon="download"
          :busy="fetching"
          :text="$t('Baixar Relatório')"
          @click="downloadReport"
        />
        <!-- Button to download report (hidden by default) -->
        <a
          ref="downloadLink"
          style="display: none"
        />
      </template>
      <b-row>
        <b-col md="5">
          <e-store-combo
            id="filter-store"
            v-model="filters.storeId"
            :required="false"
          />
        </b-col>
        <b-col md="4">
          <e-search-sku
            id="filter-sku"
            v-model="filters.skuId"
            :required="false"
            :label="$t('Produto')"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="filter-brand"
            v-model="filters.brandId"
            type="vue-select"
            :label="$t('Marca')"
            :placeholder="$t('Todas')"
            :options="getComboBrands"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="filter-group"
            v-model="filters.groupId"
            type="vue-select"
            :label="$t('Grupo')"
            :placeholder="$t('Todos')"
            :options="getComboGroups"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="filter-period"
            v-model="filters.period"
            type="date-range-picker"
            :label="$t('Período')"
            :time-picker="false"
            class="required"
            validation="required"
          />
        </b-col>
      </b-row>
    </e-filters>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { formulateValidation } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { EStoreCombo, EFilters, ESearchSku } from '@/views/components'
import EButton from '@/views/components/EButton.vue'

export default {
  components: {
    BRow,
    BCol,
    EFilters,
    EStoreCombo,
    ESearchSku,
    EButton,
  },

  mixins: [formulateValidation],

  data() {
    return {
      fetching: false,
      saving: false,
      searched: false,
    }
  },

  computed: {
    ...mapState('pages/reports/sales/skuSaleDetailed', ['reportData', 'filters']),
    ...mapGetters('pages/catalog/products', ['getComboBrands', 'getComboGroups']),
  },

  mounted() {
    this.fetchProductCombos()
  },

  methods: {
    ...mapActions('pages/reports/sales/skuSaleDetailed', ['generateReport', 'resetFilters']),
    ...mapActions('pages/catalog/products', ['fetchProductCombos']),

    async downloadReport() {
      try {
        if (this.$refs.filters.isValid()) {
          if (
            !this.filters.period.startDate ||
            !this.filters.period.endDate ||
            moment(this.filters.period.endDate).diff(
              moment(this.filters.period.startDate),
              'days'
            ) > 90
          ) {
            this.showInvalidDataMessage({
              message: this.$t('Informe um período de no máximo 90 dias para baixar o relatório'),
            })
            return
          }
          this.fetching = true
          await this.generateReport()
          // Get the download link element from the template
          const { downloadLink } = this.$refs

          if (this.reportData) {
            // Set the URL and download attributes of the link
            downloadLink.href = this.reportData
            downloadLink.download = `VendaDetalhada-Loja_${this.filters.storeId}-Data_${this.filters.period.startDate}_ate_${this.filters.period.endDate}.xlsx`

            // Click the link to start the download
            downloadLink.click()
          } else {
            this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    resetFiltersLocal() {
      this.resetFilters()
    },
  },
}
</script>

<style lang="scss" scoped></style>
